<template>
  <div class="noticeModal">
    <div class="tab" v-if="isTabShow">
      <a-tabs v-model:activeKey="activeKey" @change="doSearch">
        <a-tab-pane key="team" tab="团队"></a-tab-pane>
        <a-tab-pane key="person" tab="个人"></a-tab-pane>
      </a-tabs>
    </div>
    <section class="app-container">
      <div class="arve-tip">{{ arveTip }}</div>
      <div class="search">
        <div>
          <div
            class="form"
            v-auth="['system:arve:personalReport:rmSearch', 'system:arve:personalReport:rmLeaderSearch', 'system:arve:personalReport:lmSearch']"
            v-show="activeKey !== 'person'"
          >
            <div class="label">精确搜索：</div>
            <a-input-search
              class="item"
              placeholder="请输入人名/ID Number"
              style="width: 284px"
              @search="doSearch"
              v-model:value="formState.fullName"
              allowClear
              autocomplete="off"
            />
          </div>
        </div>
        <div>
          <a-button type="link" style="margin-right: 13px" @click="collapseClick" v-auth="['system:PersonalReport:collapse']"
          >筛选项
            <UpOutlined v-if="!collapse" />
            <DownOutlined v-else />
          </a-button>
          <a-button type="primary" @click="exportClick" v-auth="['system:PersonalReport:export']">导出</a-button>
        </div>
      </div>
      <div v-if="!collapse" class="filter">
        <div
          class="form"
        >
          <div
            class="form-item"
            v-auth="['system:arve:personalReport:lmSearch', 'system:arve:personalReport:rmSearch', 'system:arve:personalReport:rmLeaderSearch']"
            v-show="activeKey !== 'person'"
          >
            <div class="label">职级：</div>
            <a-select
              class="item"
              style="width: 240px"
              v-model:value="formState.grade"
              :options="staffGradeOps"
              @change="doSearch"
              placeholder="请选择"
              allowClear
              show-search
              mode="multiple"
            />
          </div>
          <div
            class="form-item"
            v-auth="['system:arve:personalReport:rmSearch', 'system:arve:personalReport:rmLeaderSearch']"
          >
            <div class="label">Team：</div>
            <a-select
              class="item"
              style="width: 240px"
              v-model:value="formState.orgThreeTwo"
              placeholder="请选择"
              @change="orgThreeTwoChange"
              :maxTagCount="1"
              allowClear
              show-search
              mode="multiple"
            >
              <a-select-option
                v-for="(item, index) in orgThreeTwoOps"
                :key="index"
                :value="item"
              >{{ item }}
              </a-select-option>
            </a-select>
          </div>
          <div
            class="form-item"
            v-auth="['system:arve:personalReport:rmSearch', 'system:arve:personalReport:rmLeaderSearch']"
          >
            <div class="label">Offering：</div>
            <a-select
              class="item"
              style="width: 240px"
              v-model:value="formState.orgFourOneOne"
              placeholder="请选择"
              @change="doSearch"
              :maxTagCount="1"
              allowClear
              mode="multiple"
            >
              <a-select-option
                v-for="(item, index) in orgFourOneOneOps"
                :key="index"
                :value="item"
              >{{ item }}
              </a-select-option>
            </a-select>
          </div>
          <div class="form-item">
            <div class="label">年份：</div>
            <a-date-picker
              class="item"
              style="width: 240px"
              v-model:value="formState.time"
              placeholder="请选择"
              mode="year"
              format="YYYY"
              :open="isYearOpen"
              @openChange="openChange"
              @panelChange="panelChange"
              :allowClear="false"
            />
          </div>
          <div class="form-item">
            <a-button style="margin-left: 50px" type="primary" @click="resetForm">
              重置
            </a-button>
          </div>
        </div>
      </div>
      <div class="table-box">
        <a-table
          :columns="columns"
          :data-source="tableList"
          rowKey="id"
          :scroll="{
          y: tableHeight,
          x: 1200,
          scrollToFirstRowOnChange: true,
        }"
          :pagination="pagination"
        >
          <template #infoCollapse>
            <div class="collapse-cell">
              <div class="collapse-btn" @click="infoCollapse = !infoCollapse">
                <DoubleLeftOutlined v-if="infoCollapse" />
                <DoubleRightOutlined v-else />
              </div>
            </div>
          </template>
          <template #arveCollapse>
            <div class="collapse-cell">
              <div class="collapse-btn" @click="arveCollapse = !arveCollapse">
                <DoubleLeftOutlined v-if="arveCollapse" />
                <DoubleRightOutlined v-else />
              </div>
            </div>
          </template>
          <template #bdTargetCollapse>
            <div class="collapse-cell">
              <div class="collapse-btn" @click="bdTargetCollapse = !bdTargetCollapse">
                <DoubleLeftOutlined v-if="bdTargetCollapse" />
                <DoubleRightOutlined v-else />
              </div>
            </div>
          </template>
          <template #coeTargetCollapse>
            <div class="collapse-cell">
              <div class="collapse-btn" @click="coeTargetCollapse = !coeTargetCollapse">
                <DoubleLeftOutlined v-if="coeTargetCollapse" />
                <DoubleRightOutlined v-else />
              </div>
            </div>
          </template>
          <template #idcCollapse>
            <div class="collapse-cell">
              <div class="collapse-btn" @click="idcCollapse = !idcCollapse">
                <DoubleLeftOutlined v-if="idcCollapse" />
                <DoubleRightOutlined v-else />
              </div>
            </div>
          </template>
        </a-table>
      </div>
    </section>
  </div>
</template>

<script>
import {
  defineComponent,
  onUnmounted,
  reactive,
  ref,
  nextTick,
  onActivated,
  onMounted, computed
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { dictionaryOptionsCode, downloadFile, useTableHeight } from "@/utils/common";
import { UpOutlined, DownOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import { useStore } from "vuex";

export default defineComponent({
  name: "personalReport",
  components: {
    UpOutlined,
    DownOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const store = useStore();

    const roles = computed(() => store.state.app.user.roles);
    const activeKey = ref("team");
    const isTabShow = computed(() => {
      const isLM = roles.value.indexOf("5") !== -1;
      const isConsultant = roles.value.indexOf("4") !== -1;
      return isLM && isConsultant;
    });

    const collapse = ref(false);
    const staffGradeOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const formState = ref({
      fullName: "",
      grade: [],
      orgThreeTwo: [],
      orgFourOneOne: [],
      time: moment()
    });
    const getOrgList = () => {
      //查询org3.2/org4.2
      $api.getOrgThree_FourList().then((res) => {
        const { orgThreeTwo, orgFourOneOne } = res.data;
        orgThreeTwoOps.value = orgThreeTwo;
        orgFourOneOneOps.value = orgFourOneOne;
      });
    };
    const orgThreeTwoChange = () => {
      formState.value.orgFourOneOne = [];
      if (formState.value.orgThreeTwo.length) {
        $api
          .getOrgFour({ condition: formState.value.orgThreeTwo.join() })
          .then((res) => {
            orgFourOneOneOps.value = res.data.orgFourOneOne;
          });
      } else {
        getOrgList();
      }
      doSearch();
    };

    const infoCollapse = ref(false);
    const arveCollapse = ref(false);
    const bdTargetCollapse = ref(false);
    const coeTargetCollapse = ref(false);
    const idcCollapse = ref(false);
    const collapseCellFunc = () => {
      return {
        style: {
          "background-color": "#F3F3F3"
        }
      };
    };
    const collapseTitleCellFunc = () => {
      return {
        style: {
          "background-color": "#FBFBFB"
        }
      };
    };
    const columns = computed(() => {
      let infoCols = [];
      let arveCols = [];
      let bdTargetCols = [];
      let coeTargetCols = [];
      let idcCols = [];
      if (infoCollapse.value) {
        infoCols = [
          {
            title: "Team",
            dataIndex: "orgThreeTwo",
            key: "orgThreeTwo",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 130
          },
          {
            title: "Offering",
            dataIndex: "orgFourOneOne",
            key: "orgFourOneOne",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 230
          },
          {
            title: "Grade",
            dataIndex: "grade",
            key: "grade",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>ID</div>
                  <div>Number</div>
                </div>
              );
            },
            dataIndex: "idNumber",
            key: "idNumber",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 120
          },
          {
            title: () => {
              return (
                <div>
                  <div>From</div>
                  <div>Date</div>
                </div>
              );
            },
            dataIndex: "fromDate",
            key: "fromDate",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 150
          },
          {
            title: () => {
              return (
                <div>
                  <div>To</div>
                  <div>Date</div>
                </div>
              );
            },
            dataIndex: "toDate",
            key: "toDate",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 150
          }
        ];
      }
      if (arveCollapse.value) {
        arveCols = [
          {
            title: () => {
              return (
                <div>
                  <div>JAN</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "janActualArve",
            key: "janActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>FEB</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "febActualArve",
            key: "febActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>MAR</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "marActualArve",
            key: "marActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>APR</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "aprActualArve",
            key: "aprActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>MAY</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "mayActualArve",
            key: "mayActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>JUN</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "junActualArve",
            key: "junActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>JUL</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "julActualArve",
            key: "julActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>AUG</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "augActualArve",
            key: "augActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>SEP</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "sepActualArve",
            key: "sepActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>OCT</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "octActualArve",
            key: "octActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>NOV</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "novActualArve",
            key: "novActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>DEC</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "decActualArve",
            key: "decActualArve",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          }
        ];
      }
      if (bdTargetCollapse.value) {
        bdTargetCols = [
          {
            title: () => {
              return (
                <div>
                  <div>YTD</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "ytdBd",
            key: "ytdBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>JAN</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "janBd",
            key: "janBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>FEB</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "febBd",
            key: "febBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>MAR</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "marBd",
            key: "marBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>APR</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "aprBd",
            key: "aprBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>MAY</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "mayBd",
            key: "mayBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>JUN</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "junBd",
            key: "junBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>JUL</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "julBd",
            key: "julBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>AUG</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "augBd",
            key: "augBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>SEP</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "sepBd",
            key: "sepBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>OCT</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "octBd",
            key: "octBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>NOV</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "novBd",
            key: "novBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>DEC</div>
                  <div>BD</div>
                </div>
              );
            },
            dataIndex: "decBd",
            key: "decBd",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          }
        ];
      }
      if (coeTargetCollapse.value) {
        coeTargetCols = [
          {
            title: () => {
              return (
                <div>
                  <div>YTD</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "ytdCoe",
            key: "ytdCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>JAN</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "janCoe",
            key: "janCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>FEB</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "febCoe",
            key: "febCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>MAR</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "marCoe",
            key: "marCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>APR</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "aprCoe",
            key: "aprCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>MAY</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "mayCoe",
            key: "mayCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>JUN</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "junCoe",
            key: "junCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>JUL</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "julCoe",
            key: "julCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>AUG</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "augCoe",
            key: "augCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>SEP</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "sepCoe",
            key: "sepCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>OCT</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "octCoe",
            key: "octCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>NOV</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "novCoe",
            key: "novCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>DEC</div>
                  <div>COE</div>
                </div>
              );
            },
            dataIndex: "decCoe",
            key: "decCoe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          }
        ];
      }
      if (idcCollapse.value) {
        idcCols = [
          {
            dataIndex: "idcCollapse",
            key: "idcCollapse",
            align: "center",
            width: 0,
            slots: { title: "idcCollapse" }
          },
          {
            title: "COE",
            dataIndex: "coe",
            key: "coe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: "Bench",
            dataIndex: "bench",
            key: "bench",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>Team</div>
                  <div>Management</div>
                </div>
              );
            },
            dataIndex: "teamManagement",
            key: "teamManagement",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 120
          },
          {
            title: () => {
              return (
                <div>
                  <div>Competence</div>
                  <div>Solution</div>
                </div>
              );
            },
            dataIndex: "competenceSolution",
            key: "competenceSolution",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 130
          },
          {
            title: () => {
              return (
                <div>
                  <div>EXT</div>
                  <div>Solution</div>
                  <div>Support</div>
                </div>
              );
            },
            dataIndex: "extSolutionSupport",
            key: "extSolutionSupport",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>On-Project</div>
                  <div>Support</div>
                </div>
              );
            },
            dataIndex: "onProjectSupport",
            key: "onProjectSupport",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 120
          },
          {
            title: () => {
              return (
                <div>
                  <div>Transition</div>
                  <div>Cost</div>
                </div>
              );
            },
            dataIndex: "transitionCost",
            key: "transitionCost",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 110
          },
          {
            title: () => {
              return (
                <div>
                  <div>Inventorty</div>
                  <div>Investment</div>
                </div>
              );
            },
            dataIndex: "inventortyInvestment",
            key: "inventortyInvestment",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 110
          },
          {
            title: () => {
              return (
                <div>
                  <div>HC</div>
                  <div>De-Commit</div>
                </div>
              );
            },
            dataIndex: "hcDeCommit",
            key: "hcDeCommit",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 110
          },
          {
            title: () => {
              return (
                <div>
                  <div>Booster</div>
                  <div>Program</div>
                </div>
              );
            },
            dataIndex: "boosterProgram",
            key: "boosterProgram",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: "Leave",
            dataIndex: "allLeave",
            key: "allLeave",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: "Training",
            dataIndex: "training",
            key: "training",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>Other</div>
                  <div>IDC</div>
                </div>
              );
            },
            dataIndex: "otherIdc",
            key: "otherIdc",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          }
        ];
      } else {
        idcCols = [
          {
            dataIndex: "idcCollapse",
            key: "idcCollapse",
            align: "center",
            width: 14,
            slots: { title: "idcCollapse" }
          }
        ];
      }
      return [
        {
          title: "Name",
          dataIndex: "fullName",
          key: "fullName",
          ellipsis: true,
          width: 150,
          resizable: true,
          fixed: 'left'
        },
        {
          dataIndex: "infoCollapse",
          key: "infoCollapse",
          align: "center",
          width: 0,
          slots: { title: "infoCollapse" },
          fixed: 'left'
        },
        ...infoCols,
        {
          title: () => {
            return (
              <div>
                <div>Year</div>
                <div>ARVE</div>
                <div>Target</div>
              </div>
            );
          },
          dataIndex: "yearArveTarget",
          key: "yearArveTarget",
          ellipsis: true,
          align: "center",
          width: 100,
          resizable: true
        },
        {
          title: () => {
            return (
              <div>
                <div>YTD</div>
                <div>Actual</div>
                <div>ARVE</div>
              </div>
            );
          },
          dataIndex: "ytdActualArve",
          key: "ytdActualArve",
          ellipsis: true,
          align: "center",
          width: 100
        },
        {
          dataIndex: "arveCollapse",
          key: "arveCollapse",
          align: "center",
          width: 0,
          slots: { title: "arveCollapse" }
        },
        ...arveCols,
        {
          title: () => {
            return (
              <div>
                <div>Year</div>
                <div>BD</div>
                <div>Target</div>
              </div>
            );
          },
          dataIndex: "yearBdTarget",
          key: "yearBdTarget",
          ellipsis: true,
          align: "center",
          width: 100
        },
        {
          dataIndex: "bdTargetCollapse",
          key: "bdTargetCollapse",
          align: "center",
          width: 0,
          slots: { title: "bdTargetCollapse" }
        },
        ...bdTargetCols,
        {
          title: () => {
            return (
              <div>
                <div>Year</div>
                <div>COE</div>
                <div>Target</div>
              </div>
            );
          },
          dataIndex: "yearCoeTarget",
          key: "yearCoeTarget",
          ellipsis: true,
          align: "center",
          width: 100
        },
        {
          dataIndex: "coeTargetCollapse",
          key: "coeTargetCollapse",
          align: "center",
          width: 0,
          slots: { title: "coeTargetCollapse" }
        },
        ...coeTargetCols,
        {
          title: "IDC",
          dataIndex: "idc",
          key: "idc",
          ellipsis: true,
          align: "center",
          width: 100
        },
        ...idcCols
      ];
    });

    const tableList = ref([]);
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共${total}条`;
      },
      onChange: (page, pageSize) => {
        pagination.current = page;
        pagination.pageSize = pageSize;
        getTableList();
      },
      onShowSizeChange: (_, size) => {
        pagination.current = 1;
        pagination.pageSize = size;
        getTableList();
      }
    });
    const tableHeight = ref(0);
    const tableWidth = ref(0);

    // 获取筛选框字典
    const getSelectOptions = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "grade"
          }
        })
        .then((res) => {
          staffGradeOps.value = dictionaryOptionsCode(res.data.grade);
        });
    };
    const doSearch = () => {
      pagination.current = 1;
      getTableList();
    };
    const getTableList = () => {
      const params = {
        condition: formState.value.fullName,
        gradeList: formState.value.grade,
        orgThreeTwoList: formState.value.orgThreeTwo,
        orgFourOneOneList: formState.value.orgFourOneOne,
        year: formState.value.time.format("YYYY"),
        pageIndex: pagination.current,
        pageSize: pagination.pageSize
      };
      if (isTabShow.value) {
        params.roleType = activeKey.value === "team" ? "5" : "4";
      }
      $api.getPersonalReportList(params).then((res) => {
        tableList.value = res.data.resource;
        pagination.current = res.data.pageIndex + 1;
        pagination.pageSize = res.data.pageSize;
        pagination.total = res.data.total;
      });
    };

    const resetForm = () => {
      formState.value = {
        fullName: "",
        grade: [],
        orgThreeTwo: [],
        orgFourOneOne: [],
        time: moment()
      };
      getTableList();
    };

    const collapseClick = () => {
      collapse.value = !collapse.value;
      getSize();
    };

    const isYearOpen = ref(false);
    const openChange = (panelStatus) => {
      if (panelStatus) {
        isYearOpen.value = true;
      } else {
        isYearOpen.value = false;
      }
    };
    const panelChange = (year) => {
      formState.value.time = year;
      doSearch();
      isYearOpen.value = false;
    };

    const exportClick = () => {
      const params = {
        condition: formState.value.fullName,
        gradeList: formState.value.grade,
        orgThreeTwoList: formState.value.orgThreeTwo,
        orgFourOneOneList: formState.value.orgFourOneOne,
        year: formState.value.time.format("YYYY")
      };
      if (isTabShow.value) {
        params.roleType = activeKey.value === "team" ? "5" : "4";
      }
      $api.personalReportExport(params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    const arveTip = ref("");
    const getArveTips = () => {
      $api.getArveTips().then(res => {
        arveTip.value = res.data;
      });
    }

    const getSize = () => {
      nextTick(() => {
        const bodyWidth = document.body.clientWidth;
        tableWidth.value = bodyWidth - (93 / 1920) * bodyWidth;
        tableHeight.value = useTableHeight(true, ".ant-table", 120);
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      const isRM = roles.value.indexOf("2") !== -1;
      const isRMLeader = roles.value.indexOf("11") !== -1;
      if (isRM || isRMLeader) {
        getOrgList();
      }
      getSelectOptions();
    });

    onActivated(() => {
      getArveTips();
      getSize();
      getTableList();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    return {
      isTabShow,
      activeKey,
      collapse,
      staffGradeOps,
      formState,
      resetForm,
      columns,
      tableList,
      pagination,
      tableHeight,
      tableWidth,
      getTableList,
      doSearch,
      collapseClick,
      orgThreeTwoOps,
      orgFourOneOneOps,
      orgThreeTwoChange,
      isYearOpen,
      openChange,
      panelChange,
      exportClick,
      infoCollapse,
      arveCollapse,
      bdTargetCollapse,
      coeTargetCollapse,
      idcCollapse,
      arveTip
    };
  }
});
</script>

<style lang="less" scoped>
@import "~@/style/theme.less";
//:deep(.ant-table-body) {
//  &:last-child {
//    height: calc(100vh - 158px);
//  }
//
//  &::-webkit-scrollbar {
//    width: 10px;
//    height: 10px;
//  }
//
//  &::-webkit-scrollbar-thumb {
//    width: 10px;
//    height: 10px;
//    background: #d0e8ff;
//    border: 1px solid #d0e8ff;
//    border-radius: 5px;
//  }
//}

.app-container {
  height: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}

.search {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  .form {
    margin-top: 0;
  }
}

.form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .form-item {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  .label {
    width: 110px;
    text-align: right;
    margin-right: 16px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #2d3748;
  }

  .item {
    width: 128px;
    margin-right: 40px;
  }
}

.table-box {
  margin-top: 24px;
  position: relative;

  .batch-btn {
    position: absolute;
    left: 0;
    bottom: 10px;
  }
}

.collapse-cell {
  position: relative;
}

.collapse-btn {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #d0e2f3;
  color: #3182ce;
  position: absolute;
  left: -28px;
}

.noticeModal {
  margin: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
}

:deep(.ant-tabs-nav .ant-tabs-tab) {
  font-size: 18px;
  font-weight: 500;
  margin-left: 59px;
}

:deep(.ant-tabs) {
  font-family: "PingFang SC";
  font-style: normal;
  line-height: 150%;
  color: #2d3748;

  .ant-tabs-bar {
    margin-bottom: 0;
    border-bottom: none;

    .ant-tabs-nav {
      padding: 15.5px 27px;
      background-color: #fff;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 0 20px 0 0;
    }

    .ant-tabs-extra-content {
      margin-top: 11px;
    }
  }

  /*   .ant-tabs-tabpane {
    background-color: #fff;
    padding: 24px;
    min-height: 75vh;
  } */

  .ant-tabs-tab {
    font-weight: 400;
    font-size: 18px;
    padding: 0px 16px 6px 16px;
    margin-right: 37px;

    &::before {
      transition: unset;
    }

    &.ant-tabs-tab-active {
      color: #2d3748;
      font-weight: 500;
      font-size: 20px;

      &::before {
        position: absolute;
        top: unset;
        bottom: 1px;
        left: 30%;
        width: 40%;
        background: @primary-color;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }
}

.arve-tip {
  margin-top: 24px;
  text-align: right;
  color: #f59723;
}
</style>
